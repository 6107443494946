.App {
  background-color: white;
  margin: 0px;
  padding: 0px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Google Fonts */
 @import url(https://fonts.googleapis.com/css?family=Anonymous+Pro);

.line-1{
    position: relative;
    top: 50%;  
    width: 24em;
    margin: 0 auto;
    border-right: 2px solid rgba(255,255,255,.75);
    font-size: 180%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    transform: translateY(-50%);    
}

.welcome {
  min-height: 400px;
}

.welcome-image {
  background-position: center;
    background-image: url(./images/office.jpg);
    background-repeat: no-repeat;
    background-size:cover;
    filter:contrast(.5);
    min-height: 400px;
    width:100%;
    margin:0px;
    float: left;
    position: absolute;
    left:0px;
    right:100%;
}

.welcome-text{
  display: block;
  z-index: 1;
  position: relative;
}

.row {
}

.container-fluid {
}